import api2 from '../../api2';
import { ListConnections200ResponseConnectionsInner } from '../../openApiClient';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';

type Connection = ListConnections200ResponseConnectionsInner;

type ConnectionSearchFilterProps = Omit<ObjectSearchSelectProps<Connection>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof Connection)[];
};

const ConnectionSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: ConnectionSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<Connection>
            key={filterHash}
            label={label}
            placeholder={`Select Connection${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(connection: any) => connection?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,

                        count: 50,
                        sortField: 'name',
                    };

                    const connections = (
                        await api2.client.ConnectionApi.listConnections({
                            ...query,
                            limit: 50,
                        })
                    ).data.connections;
                    if (!connections) return [];

                    return connections;
                } catch (e) {
                    console.error('Error loading connections:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const connection: Connection | null = option.value;

                if (!connection) return defaultLabel || 'All Connections';

                return (
                    <div>
                        <div>{connection?.name}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default ConnectionSearchFilter;
